jQuery( document ).ready(function() {
	// Function will set a timeout, and listen for a beforeunload. If one is fired, browser will follow that history URL. If none are fired, window will close.
	var goBackOrClose = function goBackOrClose() {
		var noBackHistoryFound = true;
		window.addEventListener("beforeunload", function() {
			noBackHistoryFound = false;
		});
		window.history.back();
		setTimeout(function() {
			if (noBackHistoryFound) {
				window.close();
			}
		}, 100);
	};
	jQuery('.helpBackButton').click(goBackOrClose);
});
