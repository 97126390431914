const cgRunScripts = function (markupString, rsn) {
	const contentDiv = document.createElement('div');
	contentDiv.innerHTML = markupString;
	const scripts = contentDiv.getElementsByTagName('script');
	for (const script of scripts) {
	  parseGoodScript(script);
	}
	function parseGoodScript(script) {
	  if (script.src) {
	  // load script
	  if (script.src.indexOf(window.location.origin) === 0) {
		jQuery.getScript(script.src);
	  } else {
		alertOrLog('Not fetching "cross-orgin" script at ' + script.src);
	  }
	  } else {
		const scriptNode = document.createElement('script');
		scriptNode.innerHTML = script.text;
			// Set the nonce only if rsn is not null or empty
		if (rsn) {
			scriptNode.setAttribute('nonce', rsn);
		}	  
		document.body.appendChild(scriptNode);
	  }
	}
	function alertOrLog(message) {
	  if (window.location.origin === 'https://www.cybergrants.com') {
	  console.log(message);
	  } else {
	  alert(message);
	  }
	}
  };
  

// Get environment path
function cgEnvGetApiPath() {
	var apiPath = '';
	switch (window.location.host) {
		case 'sandbox.cybergrants.com':
			apiPath = '/pls/cybergrants-sb/';
			break;
		case 'stg.cybergrants.com':
			apiPath = '/pls/cybergrants-stg/';
			break;
		case 'stg-sandbox.cybergrants.com':
			apiPath = '/pls/cybergrants-stgsb/';
			break;
		default:
			apiPath = '/pls/cybergrants/';
	};
	return apiPath;
}

function suppressErrors() {
	return true;
}

window.onError= suppressErrors();

var windowHandle = -1;

function openWindow(url) {
  popupWin = window.open(url, 'remote', 'toolbar,scrollbars,resizable,width=800,height=800');
  popupWin.focus();
}

function openSecondWindow(address,w,h) {
    if (w==null)
        w=650;
    if (h==null)
        h=400;

	windowHandle = window.open(address, 'secondWindow', 'toolbar=yes,scrollbars=yes,resizable=yes,status=yes,width='+w+',height='+h);
	if (windowHandle.opener == null) {
		windowHandle.opener = self;
	}
	windowHandle.focus();

	// tool bar = yes?
}

function openNewWindow(address,w,h) {
    if (w==null)
        w=650;
    if (h==null)
        h=400;

	windowHandle = window.open(address, '', 'toolbar=yes,scrollbars=yes,resizable=yes,status=yes,width='+w+',height='+h);
	if (windowHandle.opener == null) {
		windowHandle.opener = self;
	}
	windowHandle.focus();

	// tool bar = yes?
}

function selectValue(s, v) {
	for (var i = 0; i < (s.length); i++) {
		if (s.options[i].value == v)
			s.options[i].selected = true;
	}
}

function selectAll(checkboxArray) {
	if (checkboxArray) {
		if (checkboxArray.length) {
			for (var i=0; i<checkboxArray.length; i++)
				checkboxArray[i].checked = true;
		} else if (checkboxArray) {
			checkboxArray.checked = true;
		}
	}
}

function selectAllOptions(selections) {
for (var i = 0; i < (selections.length); i++) {
	selections.options[i].selected = true;
}
}

function selectNone(checkboxArray) {
	if (checkboxArray) {
		if (checkboxArray.length) {
			for (var i=0; i<checkboxArray.length; i++)
				checkboxArray[i].checked = false;
		} else if (checkboxArray) {
			checkboxArray.checked = false;
		}
	}
}

function selectNoOptions(selections) {
for (var i = 0; i < (selections.length); i++) {
	selections.options[i].selected = false;
}
}

function validate(checkboxArray, message){
	if (checkboxArray[0]) {
		var selected = 0;
		var i = 0;
		while (selected == 0 && i < checkboxArray.length) {
			if (checkboxArray[i].checked)
				selected = 1;
			i++;
		}
		if (selected != 1) {
			if (message.length > 0)
				alert(message);
			return false;
		} else
			return true;
	} else if (checkboxArray) {
		if (!checkboxArray.checked) {
			if (message.length > 0)
				alert(message);
			return false;
		}
		return true;
	}
}

function validate2(checkboxArray1, checkboxArray2, message){
	// check first array, if empty, then check the other
	if (!validate(checkboxArray1, '')) {
		return validate(checkboxArray2, message);
	} else {
		return true;
	}
}

// This function check if any value is selected on a selectArray
function validate3(selectArray, message) {
	if (selectArray[0].options) {
		for (var i=0; i < selectArray.length; i++) {
			if (selectArray[i].options[selectArray[i].selectedIndex].value != "") {
				return true;
			}
		}
	} else if (selectArray) {
		if (selectArray.options[selectArray.selectedIndex].value != "") {
			return true;
		}
	}
	alert(message);
	return false;
}

function changeList(fromList, toList, sort) {
var text = ""
var value = ""
var counter = 0
var bottom = 0
var top = 0
var insertLocation = -1
var temp = ""
var sorted = true

if (arguments.length < 3 || sort == false) { sorted = false }

while (counter < fromList.length) {
	if (fromList.options[counter].selected) {
		text = fromList.options[counter].text
		value = fromList.options[counter].value

		// if the option has no value, don't bother to move it
		if (value.length > 0) {
			insertOption = new Option(text, value)

			if (sorted) {
				top = toList.length - 1
				insertLocation = -1
				while (bottom <= top && insertLocation == -1) {
					if (toList.options[bottom].text < text) {
						bottom++
						continue
					} else {
						insertLocation = bottom
					}
				}
				if (insertLocation == -1) { insertLocation = toList.length }

				for (var i = toList.length; i > insertLocation ; i--) {
					temp =  new Option(toList.options[i - 1].text, toList.options[i - 1].value)
					toList.options[i] = temp
				}
				toList.options[insertLocation] = insertOption
			} else {
				for (var i = toList.length; i > 0 ; i--) {
					temp =  new Option(toList.options[i - 1].text, toList.options[i - 1].value)
					toList.options[i] = temp
				}
				toList.options[0] = insertOption
			}
			fromList.options[counter]=null
			formChanged=true;
		} else {
			counter++
		}
	} else {
		counter++
	}
}
}


function removeNullOption(selectBox) {
	if (selectBox)
		if (selectBox.options[0])
			if (selectBox.options[0].value == "None")
				selectBox.options[0] = null
}

function moveUp(selections) {
	for (var i = 1; i < (selections.length); i++) {
		if (selections.options[i].selected) {
			selections.insertBefore(selections.options[i], selections.options[i - 1]);
			formChanged=true;
		}
	}
}

function moveDown(selections) {
	for (var i = selections.length - 2; i >= 0; i--){
		if (selections.options[i].selected) {
			selections.insertBefore(selections.options[i], selections.options[i + 2]);
			formChanged=true;
		}
	}
}

// make sure that all of the values contain the characters listed as valid
function checkChar(item, msg, okChar) {
	var l = 0;

	// if the object is an array, loop through all values of the array
	if (item[0]) {
		for (var i = 0; i < (item.length); i++) {
			l = item[i].value.length;
			for (var j = 0; j < l; j++) {
			  if (okChar.indexOf(item[i].value.charAt(j)) < 0) {
			      alert(msg);
			      return false;
			  }
			}
		}
	} else {
		l = item.value.length;

		for (var j = 0; j < l; j++) {
		  if (okChar.indexOf(item.value.charAt(j)) < 0) {
		      alert(msg);
		      return false;
		  }
		}
	}

	return true;
}

// These two functions verify that the length of the text in a textarea is less than maxLength.
// example usage:
// <TEXTAREA NAME="x_description" ROWS=5 COLS=51 WRAP="virtual" onChange="checkLength(this, 255)"></TEXTAREA>
var global_textArray = null;
function checkLength(textArray, maxLength) {
		if (textArray.value.length > maxLength) {
			alert(CyberGrants.l10n.textOverLimit(CyberGrants.l10n.language(), textArray.value.length, maxLength));
			global_textArray = textArray;
			window.setTimeout(function() {
				global_textArray.focus();
			}, 1);
			return false;
		}
		return true;
}

// example usage:
// <FORM ACTION="admin.change_customized_app" METHOD="POST" NAME="customAppForm" onSubmit="return checkAllLengths(document.customAppForm.x_description, 255)">
function checkAllLengths(textArray, maxLength) {
	var i = 0;
	var done = false;
	var badIndex = -1;
	var l = 0;

	// if the object is an array, loop through all values of the array
	if (textArray[0]) {
		while (i<textArray.length && !done && textArray[i].value) {
			l = textArray[i].value.length;
			if (l > maxLength) {
				done = true;
				badIndex = i;
			}
			i++;
		}

		if (badIndex > -1) {
			alert(CyberGrants.l10n.textOverLimit(CyberGrants.l10n.language(), l, maxLength));
			textArray[badIndex].focus();
			return false;
		}
	} else {
		l = textArray.value.length;
		if (l > maxLength) {
			alert(CyberGrants.l10n.textOverLimit(CyberGrants.l10n.language(), l, maxLength));
			textArray.focus();
			return false;
		}
	}

	return true;
}

// This function allows checkboxes to function like radio buttons
// Used in the batch gift review screen for employee programs administration
function mimicRadio (checkboxGroup,checkbox,value) {
	var cRequestId = value.substring(0,value.indexOf("|"));
	var v;
	for (var i = 0; i < checkboxGroup.length; i++) {
		v = checkboxGroup[i].value.substring(0,checkboxGroup[i].value.indexOf("|"));
		if ((checkboxGroup[i] != checkbox) && (v == cRequestId)){
			checkboxGroup[i].checked = false;
		}
	}
}

// This function allows to check all approval action checkboxes,
// All Submitted, All Approved, All Rejected,.. etc
function checkAllApprovalAction(checkboxGroup,value) {
	var v;
	if (checkboxGroup.length) {
		for (var i = 0; i < checkboxGroup.length; i++) {
			v = checkboxGroup[i].value.substring(checkboxGroup[i].value.lastIndexOf("|")+1);
			if (v == value){
				checkboxGroup[i].checked = true;
			} else {
				checkboxGroup[i].checked = false;
			}
		}
	} else {
		v = checkboxGroup.value.substring(checkboxGroup.value.lastIndexOf("|")+1);
		if (v == value){
			checkboxGroup.checked = true;
		} else {
			checkboxGroup.checked = false;
		}
	}
}


// This function selects the option which value equals to the value of "action", for checkboxes in the checkboxGroup array
// Used in the batch gift review screen for employee programs administration
function selectActionAll(checkboxGroup, action) {
    if (checkboxGroup) {
            if (checkboxGroup[0]) {
                    for (var i=0; i < checkboxGroup.length; i++) {
                    	var v = checkboxGroup[i].value.substring(checkboxGroup[i].value.lastIndexOf("|") + 1)
                   		if (v == action) {
                   			checkboxGroup[i].checked = true;
                   			mimicRadio(checkboxGroup,checkboxGroup[i],checkboxGroup[i].value);
                    	}
                    }
            } else {
	            var v = checkboxGroup.value.substring(checkboxGroup.value.lastIndexOf("|") + 1)
            	if (v == action) {
            		checkboxGroup.checked = true;
            		mimicRadio(checkboxGroup,checkboxGroup,checkboxGroup.value);
            	}
            }
    }
}
// This function checks all textarea fields within thisForm to make sure they don't exceed 2000 char limit
function checkAllTextarea(thisForm) {
    var thisElement;
    var i;
    var maxLength;
    var field_name = /x_custom_.+_value/;
    for (i = 0; i < thisForm.elements.length; i++) {
        thisElement = thisForm.elements[i];
        maxClass = thisElement.className.match(/max[1-9]\w+/);
        if (maxClass) {
        	maxLength = parseInt(maxClass[0].replace('max',''));
        } else {
        	maxLength = 2000;
        }
        if (thisElement.type == "textarea") {
                if ((thisElement.name.match(field_name)) && (thisElement.rows == 5)){
                if (thisElement.value.length > maxLength) {
                    alert("Your text exceeded " + maxLength + " characters! Please limit your input and try submitting again.");
                    return false;
                }
            }
        }
    }
    return true;
}

// attach the form submission event onto forms with class js-checkalltextarea
// note that there may also be custom form submission events on the same form, but assuming that order does not matter
document.addEventListener("DOMContentLoaded", function() {
    var formElement = document.querySelector("form.js-checkalltextarea");
    if (formElement) {
        formElement.addEventListener("submit", function(e) {
            if (!checkAllTextarea(e.currentTarget)) {
                e.preventDefault();
                return false;
            } 
        });
    }
});

// This function will be called on all form which submits to the report server to
// prevent user submit more than once
var report_submitted = false;
function submit_report() {
        if (report_submitted) {
                return false;
        } else {
                report_submitted = true;
                return true;
        }
}

// this variable is created for the request screen to keep track of the form changes
// it's mocked in jestsetup.js.  Any changes here must be duplicated there.
var formChanged = false;
function check_unsaved(redirectUrl, locObj, promiseResolver) {
	var unsavedMessage = 'You may have unsaved changes.  If you would like to proceed without saving, click OK.  To return to save your changes, click Cancel.';
	var unsavedTitle = '';
	var stayButton = '';
	var goButton = '';
	if (jQuery('body').data('langVersion')) {
		window.cgTranslations.getTranslations().done(function() {
		  unsavedMessage = window.CyberGrants.l10n.constants.E_YOU_MAY_HAVE_UNSAVED_CHANGES;
		  unsavedMessageInModal = window.CyberGrants.l10n.constants.T_UNSAVED_CHANGES;
		  unsavedTitleInModal = window.CyberGrants.l10n.constants.T_LEAVE_PAGE;
		  goButtonInModal = window.CyberGrants.l10n.constants.T_LEAVE;
		  stayButtonInModal = window.CyberGrants.l10n.constants.T_STAY;
		});
	}
	if (window.unsavedChangesAlertEvent) { // E2: We have the special alert modal.
		window.unsavedChangesAlertEvent.data = {
			message: unsavedMessageInModal,
			title: unsavedTitleInModal,
			goButton: goButtonInModal,
			stayButton: stayButtonInModal,
			url: redirectUrl || document.activeElement.href,
			promiseResolver: promiseResolver
		};
		if (formChanged) {
			document.dispatchEvent(window.unsavedChangesAlertEvent);
			return false;
		} else {
			if (promiseResolver) {
				promiseResolver(true);
			}
			if (redirectUrl) {
				locObj.href = redirectUrl;
			}
			return true;
		}
	} else { // No special alert modal, use standard "confirm" box.
		if ((!formChanged) || (formChanged && (confirm(unsavedMessage)))) {
			if (redirectUrl) {
				locObj.href = redirectUrl;
			} else {
				if (promiseResolver) {
					promiseResolver(true);
				}
				return true;
			}
		} else if (!redirectUrl) {
			return false;
		}
	}
}
jQuery(document).ready(function() {
	jQuery('.navGhostLink').on('click', function(event) {
		event.preventDefault();
		check_unsaved(jQuery(event.currentTarget).data('ghosturl'), document.location);
	});
});

// this function is to submit the form to switch the program_type which was selected
// by THIS selectbox
function refresh_pg_type_form(pgTypeSelect, pgTypeId, pgTypeForm) {
	pgTypeId.value = pgTypeSelect.options[pgTypeSelect.selectedIndex].value;
	pgTypeForm.submit();
}

// this function is restrictly for donor search form use, should not be used by any other procedure
function refresh_and_close(loc){
//loc=http://66.89.218.167:9990/cybergrants/plsql/sg_view_req.view_reqaccess?req_id=238&org_id=1935031
    window.opener.document.location=loc;
	window.opener.document.location.reload();
	window.close();
}

// created for the survey to request interface to funnel list values from two <select> into one
function funnelListSub(fromList1, fromList2, toList) {
	var text1 = ""
	var text2 = ""
	var value1 = ""
	var value2 = ""
	var type1 = ""
	var type2 = ""
	var temp = ""

	if ((fromList1.selectedIndex >= 0) && (fromList2.selectedIndex >= 0)) {
		text1 = fromList1.options[fromList1.selectedIndex].text
		value1 = fromList1.options[fromList1.selectedIndex].value
		text2 = fromList2.options[fromList2.selectedIndex].text
		value2 = fromList2.options[fromList2.selectedIndex].value

		// type validation
		type1 = value1.substring(0,2)
		type2 = value2.substring(0,2)

		if (type1 != type2) {
			alert("Incompatible Field Type!")
			return false
		}

		// if the option has no value, don't bother to move it
		if ((value1.length > 0) && (value2.length > 0)) {
			insertOption = new Option(text1 + " => " + text2, value1 + "#" + value2)

			for (var i = toList.length; i > 0 ; i--) {
				temp =  new Option(toList.options[i - 1].text, toList.options[i - 1].value)
				toList.options[i] = temp
			}
			toList.options[0] = insertOption
			fromList1.options[fromList1.selectedIndex]=null
			fromList2.options[fromList2.selectedIndex]=null
		}
	}
	return true
}

function funnelList(fromList1, fromList2, contactList, organizationList, requestList) {
	var listValue
	var tableName
	var index

	listValue = fromList2.options[fromList2.selectedIndex].value
	index = listValue.indexOf("|",3)
	tableName = listValue.substring(3,index);

	if (tableName.toLowerCase() == "contact")
		funnelListSub(fromList1, fromList2, contactList)
	else if (tableName.toLowerCase() == "organization")
		funnelListSub(fromList1, fromList2, organizationList)
	else if (tableName.toLowerCase() == "request")
		funnelListSub(fromList1, fromList2, requestList)
}

function unfunnelList(fromList, sourceList, destinationList) {
	var text = ""
	var value = ""
	var text1 = ""
	var value1 = ""
	var text2 = ""
	var value2 = ""
	var destinationTableName
	var counter = 0
	var temp = ""
	var insertPosition
	var textIndex, valueIndex

	while (counter < fromList.length) {
		if (fromList.options[counter].selected) {
			text = fromList.options[counter].text
			value = fromList.options[counter].value

			textIndex = text.indexOf(' => ')
			valueIndex = value.indexOf('#')
			text1 = text.substring(0,textIndex)
			text2 = text.substring(textIndex+4)
			value1 = value.substring(0,valueIndex)
			value2 = value.substring(valueIndex+1)

			// put back to sourceList
			insertOption = new Option(text1, value1)
			for (var i = sourceList.length; i > 0 ; i--) {
				temp =  new Option(sourceList.options[i - 1].text, sourceList.options[i - 1].value)
				sourceList.options[i] = temp
			}
			sourceList.options[0] = insertOption

			// put back to destinationList
			// find the correct section to put back
			valueIndex = value2.indexOf("|",3)
			destinationTableName = value2.substring(3,valueIndex);
			destinationTableName = "*****" + destinationTableName.toUpperCase() + "*****"

			insertOption = new Option(text2, value2)
			for (var i = destinationList.length; i > 0 ; i--) {
				temp =  new Option(destinationList.options[i - 1].text, destinationList.options[i - 1].value)
				if (destinationList.options[i - 1].text == destinationTableName) {
					insertPosition = i
					break
				}
				destinationList.options[i] = temp
			}
			destinationList.options[insertPosition] = insertOption

			// remove list from fromList
			fromList.options[counter]=null
		} else {
			counter++
		}
	}
}

// function to validation email address line
function validate_email(mailform) {
    var valid_pattern = /^\s*\w([\.\']?[\w\-]+)*@\w([\.]?[\w\-]+)*\.[a-zA-Z0-9]+\s*$/g;
	var emailaddresses;
	if (mailform.x_recipient_email) {
		emailaddresses = mailform.x_recipient_email.value;
		if (emailaddresses.length > 0) {
			var email_ar = emailaddresses.split(",");
			for (i = 0; i < email_ar.length; i++) {
				valid_pattern = /^\s*\w([\.\']?[\w\-]+)*@\w([\.]?[\w\-]+)*\.[a-zA-Z0-9]+\s*$/g;
				if (valid_pattern.exec(email_ar[i]) == null) {
					alert(email_ar[i]+" is not a valid email address");
					return false;
				} else if (valid_pattern.exec(email_ar[i]) != null) {
				}
			}
		}
	}
	if (mailform.x_recipient_email_cc) {
		emailaddresses = mailform.x_recipient_email_cc.value;
		if (emailaddresses.length > 0) {
			var email_ar = emailaddresses.split(",");
			for (i = 0; i < email_ar.length; i++) {
				valid_pattern = /^\s*\w([\.\']?[\w\-]+)*@\w([\.]?[\w\-]+)*\.[a-zA-Z0-9]+\s*$/g;
				if (valid_pattern.exec(email_ar[i]) == null) {
					alert(email_ar[i]+" is not a valid email address");
					return false;
				} else if (valid_pattern.exec(email_ar[i]) != null) {
				}
			}
		}
	}
	if (mailform.x_recipient_email_bcc) {
		emailaddresses = mailform.x_recipient_email_bcc.value;
		if (emailaddresses.length > 0) {
			var email_ar = emailaddresses.split(",");
			for (i = 0; i < email_ar.length; i++) {
				valid_pattern = /^\s*\w([\.\']?[\w\-]+)*@\w([\.]?[\w\-]+)*\.[a-zA-Z0-9]+\s*$/g;
				if (valid_pattern.exec(email_ar[i]) == null) {
					alert(email_ar[i]+" is not a valid email address");
					return false;
				} else if (valid_pattern.exec(email_ar[i]) != null) {
				}
			}
		}
	}
	return true;
}

function togglediv(e,status,onStatus) {
	if (document.getElementById || document.all || document.layers) {
		if (onStatus == null) {
			onStatus = '';
		}

		//basic support for DHTML - IE5+ or NS6+
		if (status == 'on') {
		    document.getElementById(e).style.display = onStatus;
		} else if (status == 'off') {
		    document.getElementById(e).style.display = 'none';
		} else {
			if (document.getElementById(e).style.display == 'none') {
		    	document.getElementById(e).style.display = onStatus;
			} else {
		    	document.getElementById(e).style.display = 'none';
			}
		}
	}
//	else if (document.layers) { }
}


/*
toggle based on visibility instead of display attribute to try to avoid conflicts with embedded CSS
*/
function toggleClass(c,status) {
	var obj = getElementsByClass(c);

	for (var i = 0; i < obj.length; i++) {
		if (status == 'on') {
		    obj[i].style.display = '';
		} else if (status == 'off') {
		    obj[i].style.display = 'none';
		} else {
			if (obj[i].style.display == 'none') {
		    	obj[i].style.display = '';
			} else {
		    	obj[i].style.display = 'none';
			}
		}
	}
}

function toggleClassDisplay(c,status) {
	var obj = getElementsByClass(c);

	for (var i = 0; i < obj.length; i++) {
		if (status == 'on') {
		    obj[i].style.display = '';
		} else if (status == 'off') {
		    obj[i].style.display = 'none';
		} else {
			if (obj[i].style.display == 'none') {
		    	obj[i].style.display = '';
			} else {
		    	obj[i].style.display = 'none';
			}
		}
	}
}

/*
Visibility preserves allocated space on page
*/
function toggleClassVisibility(c,status) {
	var obj = getElementsByClass(c);

	for (var i = 0; i < obj.length; i++) {
		if (status == 'on') {
		    obj[i].style.visibility = 'visible';
		} else if (status == 'off') {
		    obj[i].style.visibility = 'hidden';
		} else {
			if (obj[i].style.visibility == 'hidden') {
		    	obj[i].style.visibility = 'visible';
			} else {
		    	obj[i].style.visibility = 'hidden';
			}
		}
	}
}


// helper functions for user-defined lists
function synchUserDefinedList(labelPrefix) {
	if (document.getElementById)
		{
			// grab the three components
			text = document.getElementById(labelPrefix + '.entry');
			select = document.getElementById(labelPrefix + '.select');
			hidden = document.getElementById(labelPrefix);

			// blank out hidden before building the new string
			hidden.value = "";
			for (var i = 0; i < select.length; i++) {
				if (i != 0) {
					hidden.value = hidden.value + String.fromCharCode(10) + select.options[i].text;
				} else {
					hidden.value = select.options[i].text;
				}
  			}

			$(labelPrefix).simulate('change');
  		}
}

function addToList(labelPrefix)
{
	if (document.getElementById)
		{
			text = document.getElementById(labelPrefix + '.entry');
			select = document.getElementById(labelPrefix + '.select');

			// create new option and add to other list
			if (text.value && text.value.length > 0) {
				insertOption = new Option(text.value);
				select.options[select.length] = insertOption;

				// clear out option from text box
				text.value = '';

				// add to hidden list form element
				synchUserDefinedList(labelPrefix);
			}
		}
	else
		alert("Element access by ID didn't work");
}

function removeFromList(labelPrefix)
{
	if (document.getElementById)
		{
			select = document.getElementById(labelPrefix + '.select');

			for (var i = select.options.length - 1; i >= 0; i--) {
				if (select.options[i].selected) {
					select.options[i] = null;
				}
  			}
			// remove from hidden list form element
			synchUserDefinedList(labelPrefix);
		}
	else
		alert("Element access by ID didn't work");
}

function getOffsetTop(cgObject) {
    if (cgObject.offsetParent) {
        return cgObject.offsetTop + getOffsetTop(cgObject.offsetParent) - 10;
    } else {
        return cgObject.offsetTop;
    }
}

function getOffsetLeft(cgObject) {
    if (cgObject.offsetParent) {
        return cgObject.offsetLeft + getOffsetLeft(cgObject.offsetParent);
    } else {
        return cgObject.offsetLeft;
    }
}

// moves all selected options on selectBox into selectedSelectBox
// used to keep the number select box in synch with the selected columns to display
function synchSelectedSelect(selectBox, selectedSelectBox)
{
	var text;
	var value;
	var selected = true;

	if (selectBox && selectedSelectBox) {
		var selectBoxLength = selectBox.length;
		var selectedSelectLength = selectedSelectBox.length;
		var selectedItems = new Object();
		var selectedItemValue;
		var selectedItemsIndex = 0;

		//record all selected selectedSelectBox items
		for (var i = 0; i < selectedSelectLength; i++) {
			selectedItemValue = selectedSelectBox.options[i].value;
			selectedItems[selectedItemValue] = selectedSelectBox.options[i].selected;
		}

		// remove all item in selectedSelectBox
		while (selectedSelectBox.length > 0) {
			selectedSelectBox.options[0] = null;
		}

		// create item in selectedSelectBox if selected in selectBox
		for (var i = 0; i < selectBoxLength; i++) {
			text = selectBox.options[i].text;
			value = selectBox.options[i].value;
			selected = selectBox.options[i].selected;

			if (selected) {
				insertOption = new Option(text, value);
				if (selectedItems[value]) {
					insertOption.selected = true;
				}
				selectedSelectBox.options[selectedSelectBox.length] = insertOption;
			}
		}
	}
} // end synchSelectedSelect
