window.loadPaginatedResults = function(targetId, url, startRow, totalRows, rowsPerPage, async) {
  jQuery("#"+targetId).html('<img src="/images/loading_24x24.gif" alt="Loading..." class="loading" />');
  jQuery.ajax({
    type: "get",
    url: url,
    dataType: "html",
    async: async === 'Y',
    data: {
      start_row: startRow,
      total_rows: totalRows,
      rows_per_page: rowsPerPage
    },
    success: function(result) {
      jQuery("#"+targetId).html(result);
      try {
        CyberGrants.initQuickView();
      } catch (error) {
        console.warn('error in initQuickView()');
      }
    }
  });
}

jQuery(document).ready(function() {
  jQuery('body').on('click', 'a.js-pagination', function(e) { 
    e.preventDefault(); 
    var targetId = jQuery(e.currentTarget).data('target-id'); 
    var startRow = jQuery(e.currentTarget).data('start-row'); 
    var totalRows = jQuery(e.currentTarget).data('total-rows'); 
    var rowsPerPage = jQuery(e.currentTarget).data('rows-per-page'); 
    var asynchronous = jQuery(e.currentTarget).data('asynchronous'); 
    loadPaginatedResults(targetId, e.currentTarget.href, startRow, totalRows, rowsPerPage, asynchronous); 
  }); 
});
