/*! multiLogo.js - Operates on Website Setup pages
    (Emp App Config/General, App Config/Default Website Setup)
    Handles both MultiLogo and custom theme color picker
*/
jQuery(document).ready(function(){
  const alternateLogo = jQuery('#alternateLogo');

  if (alternateLogo.length >= 1) {
    const template = `
      <div class="formRow form__section--nested-section" id="formRowx_logo_path">
        <label for="x_logo_path" class="formLabel label">Client/Program Logo:
          <div class="cg-tooltip-icon">
            <a rel="x_logo_path" title="&lt;p&gt;The following general guidelines apply:&lt;ul&gt;&lt;li&gt;Image width should be 280px (recommended size, but can be more than 280)&lt;br&gt;OR&lt;br&gt;Image height should be 144px (recommended size, but can be more than 144)&lt;/li&gt;&lt;li&gt;Background should be transparent&lt;/li&gt;&lt;li&gt;Logo should be cropped up to the left and right edges of the image&lt;/li&gt;&lt;li&gt;File format should be PNG (JPG also allowed but not recommended)&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;" tabindex=0>
              <span class="hintText">(?)</span>
            </a>
          </div>
        </label>
        <div class="formFloater">
          <div class="form__section--nested-section">
            <img id="default-logo" class="logo-default" src="" />
            <div class="descriptionText description">
              <p class="p--background-styled">Provide the relative path to the image hosted by CyberGrants (requires that the image first be uploaded to the CyberGrants server by staff), e.g. "/client/eg_logo.png".</p>
            </div>
            <input id="default-logo-input" value="" type="text" class="logo-default formInput isReadOnlyDisable input-text" maxlength="255" name="x_logo_path"/>
            <button id="default-logo-preview-button" class="button formButton logo-default button--secondary">Preview</button>
            <div class="smallErrorText hidden form__section--nested-section">Please use a correct pathname eg:"/folder/image-name.jpg or png"</div>
          </div>
        </div>
          <label class="formLabel label">Configure alternate branding by:
            <div class="cg-tooltip-icon">
              <a title="<p>Select one employee attribute to use to display an alternate logo. Any donors not falling into one of the extra scenarios that you define will receive the default client/program logo.</p>" tabindex=0>
              <span class="hintText">(?)</span>
            </a>
            </div>
          </label>
          <div class="formFloater">
            <div>
              <select id="brand-by-option-selector" name="x_alternate_logo_type" class="formSelect isReadOnlyDisable select">
              <!-- Options will be added dynamically -->
              </select>
            </div>
          </div>
      </div>

      <div class="additionalLogosContainer">
        <!-- section will be added dynamically -->
      </div>
      <div class="formRow">
        <div class="formFloater">
          <div id="add-more-logos-container">
            <button id="add-more-logos-button" class="formButton isReadOnlyDisable button--secondary">Add Another Logo</button>
          </div>
        </div>
      </div>
    `;
    const emptyTemplate = `
      <div class="individual-logo-container" name="x_logo-placeholder">
        <div class="formRow">
          <div class="formFloater">
            <img src="" name="x_logo-placeholder"/>
          </div>
          <label class="formLabel label"><span class="asterisk">*</span>Please make your selections:
            <div class="cg-tooltip-icon">
              <a rel="x_logo_path" title="Pick the attributes that the donor must have in order to receive this particular logo." tabindex=0>
                <span class="hintText">(?)</span>
              </a>
            </div>
          </label>
          <div class="formFloater">
            <select id="formSelectMulti" class="isReadOnlyDisable formSelectMulti multiple-select" multiple="multiple" name="x_brand_id-placeholder">
              <!-- Options will be added dynamically -->
            </select>
          </div>
          <label class="formLabel label"><span class="asterisk">*</span>Alternate client/program logo:
            <div class="cg-tooltip-icon">
              <a rel="x_logo_path" title="&lt;p&gt;The following general guidelines apply:&lt;ul&gt;&lt;li&gt;Minimum image width should be 900px&lt;/li&gt;&lt;li&gt;Background should be white or transparent&lt;/li&gt;&lt;li&gt;Logo should be cropped up to the left and right edges of the image&lt;/li&gt;&lt;li&gt;File format should be png or jpg&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;" tabindex=0>
                <span class="hintText">(?)</span>
              </a>
            </div>
          </label>
          <div class="formFloater">
            <input value="" type="text" class="isReadOnlyDisable preview-input input-text" name="x_logo-placeholder"/>
            <button class="isReadOnlyDisable preview-button formButton button--secondary" name="x_logo-placeholder">Preview</button>
            <p class="smallErrorText hidden">Please specify a logo path in the form "/folder/...png" or "/folder/...jpg"</p>
          </div>
          <div class="formFloater">
            <button class="button formButton isReadOnlyDisable logo-delete-button button--secondary--red" name="x_logo-placeholder">Delete</button>
          </div>
        </div>
      </div>
    `;
    let allLogoData = {};
    let currentVal = "";
    const getBrandOptionVal = function(){
      return jQuery('#brand-by-option-selector').val();
    }
    const getCurrentVal = function(){
      return allLogoData.brandBy;
    }
    const getCurrentBrandOptions = function(options){
      return options.filter((brand) => brand.id == allLogoData.brandBy)[0].options;
      ;
    }
    const reindexAllElements = function(){
      //  set the index of all elements
      const targetText = '[name^="x_logo"]';
      jQuery('div'+targetText).each(function(i){
        jQuery(this).attr('name', 'x_logo' + (i+1));
      });
      jQuery('img'+targetText).each(function(i){
        jQuery(this).attr('name', 'x_logo' + (i+1));
      });
      jQuery('input'+targetText).not('input[name="x_logo_path"]').each(function(i){
        jQuery(this).attr('name', 'x_logo' + (i+1));
      });
      jQuery('button.preview-button'+targetText).each(function(i){
        jQuery(this).attr('name', 'x_logo' + (i+1));
      });
      jQuery('select[name^="x_brand_id"]').each(function(i){
        jQuery(this).attr('name', 'x_brand_id' + (i+1));
      });
      jQuery('button.logo-delete-button'+targetText).each(function(i){
        jQuery(this).attr('name', 'x_logo' + (i+1));
      });
    }

    // Loading Spinner
    alternateLogo.html('<div class="large-loading-icon"></div>');

    // Get JSON
    if (jQuery.isEmptyObject(allLogoData)) {
      jQuery.ajax({
        url: alternateLogo.attr('alternate-logo-url'),
        dataType: 'json',
        type: 'GET',
        success: function(logoData) {
          allLogoData = logoData;
          renderLogos();
        }
      });
    }

    // Combine Main Template and JSON and render html
    function renderLogos(){
      alternateLogo.html('');

      // Append the main template HTML
      alternateLogo.append(template);

      // Assigning default logo URL
      jQuery('#default-logo').attr('src', allLogoData.defaultLogoUrl);
      jQuery('#default-logo-input').val(allLogoData.defaultLogoUrl);

      // Loop through each logo in the allLogoData.logos array and append it
      allLogoData?.logos?.forEach(function(logo, index) {
        // Safeguard against undefined index
        const logoIndex = index || 0;

        const logoHtml = `
          <div class="individual-logo-container logo-${logoIndex}" name="x_logo${logoIndex+1}">
            <div>
              <div class="formRow">
                <div class="formFloater">
                  <img src="${logo.url || ''}" class="logo-${logoIndex}" name="x_logo${logoIndex+1}"/>
                </div>
                <label class="formLabel label"><span class="asterisk">*</span>Please make your selections:
                  <div class="cg-tooltip-icon">
                    <a rel="x_logo_path" title="Pick the attributes that the donor must have in order to receive this particular logo." tabindex=0>
                      <span class="hintText">(?)</span>
                    </a>
                  </div>
                </label>
                <div class="formFloater">
                  <select 
                    class="formSelectMulti isReadOnlyDisable multiple-select" 
                    multiple="multiple" 
                    name="x_brand_id${logoIndex+1}"
                  >
                    ${populateSelectOptions(logo.brandingIds || [])}
                  </select>
                </div>
                <label class="formLabel label"><span class="asterisk">*</span>Alternate client/program logo:
                  <div class="cg-tooltip-icon">
                    <a rel="x_logo_path" title="&lt;p&gt;The following general guidelines apply:&lt;ul&gt;&lt;li&gt;Minimum image width should be 900px&lt;/li&gt;&lt;li&gt;Background should be white or transparent&lt;/li&gt;&lt;li&gt;Logo should be cropped up to the left and right edges of the image&lt;/li&gt;&lt;li&gt;File format should be png or jpg&lt;/li&gt;&lt;/ul&gt;&lt;/p&gt;" tabindex=0>
                      <span class="hintText">(?)</span>
                    </a>
                  </div>
                </label>
                <div class="formFloater">
                  <input value="${logo.url || ''}" type="text" class="input-text isReadOnlyDisable preview-input logo-${logoIndex}" name="x_logo${logoIndex+1}" />
                  <button class="preview-button formButton button--secondary logo-${logoIndex}" name="x_logo${logoIndex+1}">Preview</button>
                  <p class="smallErrorText hidden">Please specify a logo path in the form "/folder/...png" or "/folder/...jpg"</p>
                </div>
                <div class="formFloater">
                  <button class="isReadOnlyDisable button formButton logo-delete-button button--secondary--red" name="x_logo${logoIndex+1}">Delete</button>
                </div>
              </div>
            </div>
          </div>
        `;

        jQuery('.additionalLogosContainer').append(logoHtml);
      });

      // Populate the brand options and multi-select after appending the logos
      const options = getCurrentBrandOptions(allLogoData.brandByOptions);
      populateMultipleSelect(allLogoData, options);
      populateBrandOptions(allLogoData.brandByOptions);

      // Apply post-rendering functions
      postRenderingFunctions();
      registerButtonFunctions();
      ajaxifyFormPost();
      isReadOnly();

      // Helper function to populate select options
      function populateSelectOptions(selectedBrandingIds) {
        return allLogoData.brandByOptions.map(function(option) {
          const optionElement = jQuery('<option>')
          .val(option.id)
          .attr('selected', selectedBrandingIds.includes(option.id) ? 'selected' : '')
          .text(option.name);
          return optionElement;
        }).join('');
    }
  }

    function populateBrandOptions(brandByOptions) {
      const selector = jQuery('#brand-by-option-selector');
      selector.empty();  // Clearing any existing options

      jQuery.each(brandByOptions, function(index, option) {
         // Option elements
         const optionElement = jQuery('<option>')
         .attr('data-id', option.id)
         .attr('selected', option.id === getCurrentVal() ? true : '')
         .val(option.id)
         .text(option.name);

        selector.append(optionElement);
      });
    }
    
    function populateMultipleSelect(allLogoData, options) {
      const selector = jQuery('.formFloater .formSelectMulti');
      selector.empty();  // Clearing any existing options

      // Populate the select .formSelectMulti options
      if(options.length > 0){
        jQuery.each(options, function(index, option) {
          const optionId = option.brandingId.toString();
          
          // Check if the option is selected and allLogoData.logos is not empty
          const selected = allLogoData?.logos && allLogoData?.logos[0]?.brandingIds?.includes(optionId);

          // Option elements
          const optionElement = jQuery('<option>')
            .attr('data-id', optionId)
            .val(optionId)
            .text(option.name);

          if (selected) {
            optionElement.prop('selected', true);
          }
  
          selector.append(optionElement);
        });
      }
    }

    // Render an empty new logo block
    function renderEmptyLogos() {
      allLogoData.brandBy = getBrandOptionVal();
      const options = getCurrentBrandOptions(allLogoData.brandByOptions);
      jQuery('.additionalLogosContainer').append(emptyTemplate);
      postRenderingFunctions();
      populateMultipleSelect(allLogoData, options);
      // registerButtonFunctions();
    }

    // Check for Read Only, set inputs to read only if true
    function isReadOnly() {
      const readOnly = alternateLogo.attr('read-only');
      if (readOnly === 'Y') {
        jQuery('.isReadOnlyDisable').prop('disabled', true);
      }
    }
    // Ajax Posting
    function ajaxifyFormPost() {
      jQuery("[name='eg_config_form']").on('submit', function(event) {
        event.preventDefault();
        const form = jQuery(this);
        const formAction = form.attr('action');
        const formData = form.serialize();
        jQuery.ajax(formAction, {
          data: formData,
          type: 'POST',
          dataType: 'text json',
          success: handleFormResponse
        });
      });
    }
    //Ajax response
    function handleFormResponse(response) {
      jQuery.each(response, function(index, element) {
        if (index === 'success') {
            jQuery('#message-container').html("");
            jQuery('#message-container').append('<div class="formSuccess"><h3>Success!</h3><p>'+this+'</p></div>');
            jQuery('html,body').animate({ scrollTop: 0 }, 'slow');
        } else if (index === 'errors') {
            jQuery('#message-container').html('<div class="formErrors"><h3>Error!</h3><p>Please fix the following errors;</p><ul></ul></div>');
            jQuery('.formErrors').find('ul').append(this);
            jQuery('html,body').animate({ scrollTop: 0 }, 'slow');
        }
      });
    }

    function registerButtonFunctions(){
      jQuery('#add-more-logos-button').click (function(e){
        e.preventDefault();
        renderEmptyLogos();
      });
      jQuery('#brand-by-option-selector').change(function(e){
        e.preventDefault();
        const newVal = jQuery(this).val();
        if (confirm("Are you sure you want to change this? Your current settings will be deleted upon save.")) {
          jQuery(this).val(newVal);
          jQuery('.additionalLogosContainer').html("");
          postRenderingFunctions();
          } else {
            jQuery(this).val(currentVal);
            postRenderingFunctions();
        }
      });
    }

    function postRenderingFunctions(){
      cgBuildTooltips();

      const defaultLogoPreviewButton = jQuery('#default-logo-preview-button');
      const addMoreLogosButton = jQuery('#add-more-logos-button'); 
      const previewInput = jQuery('.preview-input');
      const defaultLogoInput = jQuery('#default-logo-input');

      // Do All The Inputs!!!!!
      defaultLogoPreviewButton.click (function(e) {
        e.preventDefault();
        const newLogo = defaultLogoInput.val();
        const testPathSlash = (/^\//.test(newLogo));
        const testPathHttp = (/^http/.test(newLogo));
        const testPathEmpty = (/ /.test(newLogo));
        if (testPathSlash === false && testPathHttp === false && testPathEmpty === true || testPathSlash === true && testPathHttp === false && testPathEmpty === false) {
          jQuery(this).next().addClass('hidden');
          jQuery('#default-logo').attr('src', newLogo);
        } else if (testPathSlash === false && testPathHttp === false && testPathEmpty === false || testPathSlash === false && testPathEmpty === true && testPathEmpty === false) {
          jQuery(this).next().removeClass('hidden');
        }
      });
      jQuery('.preview-button').click (function(e){
        e.preventDefault();
        const grabName = jQuery(this).attr('name');
        const nameMarkup = '[name="'+grabName+'"]';
        const newLogo = jQuery('input'+nameMarkup).val();
        const testPathSlash = (/^\//.test(newLogo));
        const testPathHttp = (/^http/.test(newLogo));
        const testPathEmpty = (/^\s*$/.test(newLogo));
        if (testPathSlash === false && testPathHttp === false && testPathEmpty === true || testPathSlash === true && testPathHttp === false && testPathEmpty === false) {
          jQuery(this).next().addClass('hidden');
          jQuery('img'+nameMarkup).attr('src', newLogo);
        } else if (testPathSlash === false && testPathHttp === false && testPathEmpty === false || testPathSlash === false && testPathEmpty === true && testPathEmpty === false) {
          jQuery(this).next().removeClass('hidden');
        }
      });
      previewInput.keypress(function(e){
        if(e.which == 13){
          e.preventDefault();
          const grabName = jQuery(this).attr('name');
          const nameMarkup = '[name="'+grabName+'"]';
          jQuery('.preview-button'+nameMarkup).click();
        }
      });
      defaultLogoInput.keypress(function(e){
        if(e.which == 13){
          e.preventDefault();
          defaultLogoPreviewButton.click();
        }
      });
      previewInput.blur(function(e){
        e.preventDefault();
        const grabName = jQuery(this).attr('name');
        const nameMarkup = '[name="'+grabName+'"]';
        jQuery('.preview-button'+nameMarkup).click();
      });
      defaultLogoInput.blur(function(e){
        e.preventDefault();
        defaultLogoPreviewButton.click();
      });
      jQuery('.logo-delete-button').click (function(e){
        e.preventDefault();
        const grabName = jQuery(this).attr('name');
        const nameMarkup = '[name="'+grabName+'"]';
        jQuery('.individual-logo-container'+nameMarkup).remove();
        reindexAllElements();
      });
      if (getBrandOptionVal() === "") {
        addMoreLogosButton.addClass('hidden');
        jQuery('.additionalLogosContainer').html("");
      } else {
        addMoreLogosButton.removeClass('hidden');
      }

      // get current val of "branding by" to save for change confirm box
      currentVal = getCurrentVal();
      //  set the index of all elements
      reindexAllElements();
    }
  }

  if (jQuery('[name=websetup_form] #theme-table').length) {
    const customInput = '#theme-table [name=x_primary_color]';
    jQuery('#theme-table tbody tr').css({cursor: 'pointer'});

    if (jQuery(customInput).val().length) {
      // fill in preview of existing custom color
      handleCustomColor(jQuery(customInput));
    }
    jQuery('body')
      .on('click', '#theme-table tbody tr', function () {
        // courtesy method to make whole row clickable
        jQuery(this).find('input[name=x_standard_color]').prop('checked', true);
        handleThemeChange(jQuery(this).find('input')[0]);
      })
      .on('keyup', customInput, function(e) {
        handleCustomColor(e.target);
      })
      .on('focus', customInput, function() {
        // select the radio button when the custom color input gets focus
        jQuery(this).closest('tr').find('td:first input').prop('checked', true);
      })
      .on('focus', '[name=x_standard_color]:last', function() {
        // when custom color radio is clicked, autofocus the text input
        jQuery(customInput).focus();
      });

    // Clear custom color preview AND value when selecting a standard color
    function handleThemeChange(target) {
      // don't clear existing value if clicking in the custom color row
      if (jQuery(target).closest('tr').find('[name=x_primary_color]').length) {
        return;
      }
      if (jQuery(target).val()) {
        jQuery(customInput).val('')
          .closest('td')
          .next('td').find('div')
            .removeAttr('style')
            .text('');
      }
    }
    // Update the preview div
    function handleCustomColor(target) {
      const newVal = jQuery(target).val();

      jQuery(customInput)
        .closest('td')
        .next('td')
        .find('div')
          .css({backgroundColor: newVal, color: 'white'})
          .text(newVal);
    }
  }
});