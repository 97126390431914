/*! Field manipulations needed for foreign currency UI */
/*  
  CG internal and external
  2019-10-31 Happy Halloween!
  started by: @eleanor.oneill@cybergrants.com
  redone by Ryan Royalty 2022-08-04 
*/

var CgFxCurrency = (function () {

  /*
    Object for holding various codes, rates, and precisions related to the different possible
    FX fields that can be rendered on one page. There can only be one set of base settings,
    but with the addition of functionality to batch payments and approvals, the rest of the settings are
    assigned to an array.
  */
    var fx =  { 
      fxArray: [],
      base_currency_format: null,
      base_code: null,
      base_rate: null,
      base_precision: null,
      base_currency_symbol: null,
      group_separator: null
    };
  
  /*  
    Initialization function.
    First, stores base_code and base_currency_format.
    Second, Loops through the fxcWrapperElements passed into it upon page load and deterimines what type of FX
    field is being rendered; Grant, Payment, Request. Pushes relevant code, request_id, and/or payment_id to array, depending on what's 
    applicable. 
  */
    fx.init = function (fxcWrapperElements, fxcSelector) {
      fx.base_code = jQuery('body').data('base-currency-code') || 'USD';
      fx.base_currency_format = jQuery('body').data('base-currency-format') || 'fm99999999990D00';
      if (fxcWrapperElements.length > 0 || jQuery('#x_deductible_amount').length > 0) {
        fx.lookupCurrencyRate(null, fx.base_code, 'base', null);
      };

      fxcWrapperElements.each(function (dataIndex) {
        jQuery(this).attr('data-fx-index', dataIndex);
  
        var grantCode = null;
        var paymentCode = null;
        var requestID = jQuery(this).data('fx-req-id');
        var paymentID = jQuery(this).data('fx-pmt-id');
        var fxType = jQuery(this).data('fx-type') || 'request';
        var fxCode = jQuery(this).find(fxcSelector).val() || fx.base_code;
        var requestCode = jQuery('[data-column-name*=request][data-column-name$=fxc],[name*=request][name$=fxc],[id*=request][id$=fxc]').find('input,select').val() || fxCode;
        if (fxType === 'scheduled') {
          jQuery('#x_number_of_payments').attr('data-sched-index', dataIndex);
        };
  
        if (fxType === 'grant') {
          grantCode = fxCode;
        } else if (fxType === 'payment' || fxType === 'scheduled') {
          paymentCode = fxCode;
        };
  
        fx.fxArray.push({
          wrapper_selector: 'form [data-fx-index='+dataIndex+']',
          fxc_selector: fxcSelector,
          fx_type: fxType,
          request_code: requestCode,
          request_rate: null,
          request_precision: null,
          payment_code: paymentCode,
          payment_rate: null,
          payment_precision: null,
          payment_fxa_precision: null,
          grant_code: grantCode,
          grant_rate: null,
          grant_precision: null,
          request_id: requestID,
          payment_id: paymentID
        });
      });
  
    /*  
      After the fxArray is populated:
      Iterates through newly created array for each element, uses associated fx code to look up currency rate and precision.
      Additionally it creates event listeners for each FXA and fxc field to re-render amounts and codes, and update
      array elements depending on what gets changed.
    */
      jQuery(fx.fxArray).each(function (arrayIndex, arrayElement) {
        var listenerElement = jQuery(arrayElement.wrapper_selector).find(arrayElement.fxc_selector); // fxc field (input or select)
        var effectiveCode = arrayElement.payment_code || arrayElement.grant_code || arrayElement.request_code || fx.base_code;
  
        fx.lookupCurrencyRate(arrayIndex, arrayElement.request_code, 'request', arrayElement.request_id); // Always do a Request Lookup
        if (arrayElement.fx_type !== 'request') { // Grant lookup (for Grant and Payment)
          fx.lookupCurrencyRate(arrayIndex, effectiveCode, arrayElement.fx_type, arrayElement.request_id, arrayElement.payment_id);
        };
  
        // Listeners for changes to fxc fields.
        jQuery(listenerElement)
          .on('change', function () {        
            var dataIndex = jQuery(this).closest('[data-fx-index]').data('fx-index');
            var elementValue = this.value;
            fx.lookupCurrencyRate(dataIndex, elementValue, fx.fxArray[dataIndex].fx_type, fx.fxArray[dataIndex].request_id, fx.fxArray[dataIndex].payment_id)
                .done(function () {
                if (fx.fxArray[dataIndex].fx_type === 'request') {
                  fx.updateRequestFxCodes(elementValue, fx.fxArray[dataIndex].request_code, fx.fxArray[dataIndex].request_id); // Changes all request FX codes in DOM
                  fx.updateRequestRates(dataIndex); // Changes all FX and Base rates and values in array and in DOM.
                } else {
                  fx.updateFXRate(dataIndex);
                };
            });
          });
      });
  
      // Listeners for changes to FXA fields.
      jQuery('body')
        .on('keyup input', 'form [data-fxa-field] input', function () {
          var dataField = jQuery(this).closest('[data-fxa-field]').data('fxa-field');
          var dataIndex;
  
          if (jQuery('[data-fxc-field='+dataField+']').data('fx-type') === 'scheduled') { 
            dataIndex = jQuery('#x_number_of_payments').data('sched-index');
            fx.calcScheduleTotal(dataIndex);
          } else {
            dataIndex = jQuery('form [data-fxc-field='+dataField+']').data('fx-index');
          };
          fx.updateFXRate(dataIndex);
        })
        .on('keyup input', 'form #x_number_of_payments', function () { // Update scheduled payment totals when number of payments changes, too.
          dataIndex = jQuery('#x_number_of_payments').data('sched-index');
          fx.calcScheduleTotal(dataIndex);
        })
        // single currency versions of the deductible amount updates on change to:
            // x_amount or
            // x_quid_pro_quo_amount
        .on('keyup input', '#x_amount', function() {
          fx.updateDeductibleAmountForRequest(requestId, false);
        })
        .on('keyup input', '#x_quid_pro_quo_amount', function() {
          fx.updateDeductibleAmountForRequest(requestId, false);
        });
  
        if (fx && fx.fxArray[0] && fx.fxArray[0].request_id) {
          // Update Deductible amount field on change to:
            // x_amount_fxa or
            // x_quid_pro_quo_amount_fxa
          var requestId = fx.fxArray[0].request_id;
          jQuery('body')
          .on('keyup input', '#x_amount_fxa' + requestId, function() {
            fx.updateDeductibleAmountForRequest(requestId, true);
          })
          .on('keyup input', '#x_quid_pro_quo_amount_fxa' + requestId, function() {
            fx.updateDeductibleAmountForRequest(requestId, true);
          });
        }
    };
  
  /*
    AJAX Get for rate and precision based on code, type, request id, and payment id.
  */
    fx.lookupCurrencyRate = function (arrayIndex, fxc, whichRate, requestID, paymentID) {
      var whichRateTemp = whichRate;
      if (whichRate === 'scheduled') {
        whichRateTemp = 'payment';
      };
      var activeFX = fx.fxArray[arrayIndex];
      var sql_agt = CyberGrants.constants.SQL_AGENT;
      var url = sql_agt + 'req.currency_conversion_rate';
      
      return jQuery.ajax({
        url: url,
        method: 'get',
        dataType: 'text',
        data: {
          x_request_id: requestID,
          x_currency_code: fxc,
          x_ts: new Date().getTime(),
          x_currency_type: whichRateTemp,
          x_payment_id: paymentID || ''
        },
        success: function (resp) {
          var json = jQuery.parseJSON(resp);
          if (json.success) {
            var newRate = json.success.rate;
            var newPrecision = parseInt(json.success.precision, 10);
            var newCurrencySymbol = json.success.basecurrencysymbol;
            var newGroupSeparator = json.success.groupseparator;
            switch (whichRateTemp) {
              case 'base':
                fx.base_rate = newRate;
                fx.base_precision = newPrecision;
                fx.base_currency_symbol = newCurrencySymbol;
                fx.group_separator = newGroupSeparator;
                break;
              case 'grant':
                activeFX.grant_rate = newRate;
                activeFX.grant_precision = newPrecision;
                break;
              case 'request':
                activeFX.request_rate = newRate;
                activeFX.request_precision = newPrecision;
                break;
              case 'payment':
                activeFX.payment_rate = newRate;
                activeFX.payment_precision = newPrecision;
                activeFX.payment_fxa_precision = parseInt(json.success.fxaprecision, 10);
                break;
            }}},
          error: function (resp, err) {
            console.log('something went wrong.')
          }}); 
        };
  
  /* 
    Rerenders displayed request fxc field currency codes for all Request fxc fields by request ID
    when the selected code value changes.
  */
    fx.updateRequestFxCodes = function (newCode, reqCode, requestID) {
      var updatedCode = newCode || reqCode || fx.base_code;
      var fxcRequestElements = jQuery('[data-fx-req-id='+requestID+']');
      fxcRequestElements.each(function (fxcIndex, fxcElement) {
        var fxField = jQuery(fxcElement).data('fxc-field');
        var fxcUpdate = jQuery('[data-fxc-field='+fxField+'],[data-fxa-field='+fxField+']').find('.currency-code-display.request');
        if (fxcUpdate.length === 0) {
          var fxcUpdate = jQuery('[data-fxc-field='+fxField+'],[data-fxa-field='+fxField+']').siblings('.currency-code-display.request'); 
        };
        jQuery(fxcUpdate).text(updatedCode);
      });
    };
  
  /*
    Updates all request fx field array element rates by request ID, and then updates and renders the base 
    rates and values for those request fx fields.
  */
    fx.updateRequestRates = function (arrayIndex) {
      var activeFX = fx.fxArray[arrayIndex];
      var rate = activeFX.request_rate || fx.base_rate;
      var precision = activeFX.request_precision || fx.base_precision;
  
      if (rate && precision !== null) {
        var requestFilterArray = fx.fxArray.filter(function(arrayElement) { return arrayElement.fx_type === 'request' && arrayElement.request_id === activeFX.request_id });
  
        // FX Rates in Array
        jQuery(requestFilterArray).each(function (arrayIndex, arrayElement) {
          arrayElement.request_rate = rate;
          arrayElement.request_precision = precision;
        });
  
        // Base Rates
        var fxcRequestElements = jQuery('[data-fx-type=request][data-fx-req-id='+activeFX.request_id+']');
        fxcRequestElements.each(function (fxcIndex, fxcElement) {
            var fxField = jQuery(fxcElement).data('fxc-field');
            var inputElement = jQuery('[data-fxa-field='+fxField+']').find('input');
            var baseElement = jQuery('[data-fxa-field='+fxField+']').find('.js-currency-base-fxa');
            fx.updateBaseRate(inputElement, baseElement, rate, precision);
        });
      } else {
        if (!rate) { console.log('could not find request specific rate for request '+activeFX.request_id) };
        if (!precision) { console.log('could not find request specific precision for request '+activeFX.request_id) };
      };
    };
  
    // Update rate and precision if fxc changes.
    fx.updateFXRate = function (arrayIndex) {
      var activeFX = fx.fxArray[arrayIndex];
  
      if (activeFX.fx_type === 'request') {
        var rate = activeFX.request_rate || fx.base_rate;
        var precision = activeFX.request_precision || fx.base_precision;
      } else if (activeFX.fx_type === 'grant') {
        var rate = activeFX.grant_rate || activeFX.request_rate || fx.base_rate;
        var precision = activeFX.grant_precision || activeFX.request_precision || fx.base_precision;
      } else if (activeFX.fx_type === 'payment' || activeFX.fx_type === 'scheduled') {
        var rate = activeFX.payment_rate || activeFX.grant_rate || activeFX.request_rate || fx.base_rate; 
        var precision = activeFX.payment_precision || activeFX.grant_precision || activeFX.request_precision || fx.base_precision;
      };
  
      if (rate && precision !== null) {
        var fxField = jQuery(activeFX.wrapper_selector).data('fxc-field');
        var inputElement = jQuery('[data-fxa-field='+fxField+']').find('select, input');
        var baseElement = jQuery('[data-fxa-field='+fxField+']').find('.js-currency-base-fxa');
        fx.updateBaseRate(inputElement, baseElement, rate, precision);
      } else {
        if (!rate) { console.log('could not find request specific rate for array element' + activeFX ) };
        if (!precision) { console.log('could not find request specific precision for array element' + activeFX ) };
      };
    };
  
    fx.addGroupSeparator = function (amt, precision) {
      if (fx.base_currency_format.indexOf('G') > -1) {
        amt = Intl.NumberFormat('en-US', {style: 'decimal',  minimumFractionDigits: precision}).format(amt);
      }
      return amt;
    }
  
    /* Main base rate updater
       @input: jQuery input node
       @output: jQuery node where text is updated
       @rate: Rate associated with either payment, grant, request, or base code, depending on where function is called.
       @precision: Precision associated with either payment, grant, request, or base code, depending on where function is called.
    */
    fx.updateBaseRate = function (input, output, rate, precision) {
      var text_regex = /[0-9\.\, ]+|Invalid amount/;
      var amt = input.val() || input.text() || 0;
      if (typeof amt === 'string') {
        amt = amt.replace(/[^0-9\.]/g, '');
        amt = parseFloat(amt);
      }
      var newAmt = amt;
      if (isNaN(amt)) {
        newAmt = 'Invalid amount ';
      } else {
        if (rate && precision !== null) { // CG-18858 precision can be 0 (JPY)
          newAmt = (amt * rate);
          newAmt = +(Math.round(Number(newAmt + "e+"+precision)) + "e-" + precision);
          newAmt = newAmt.toFixed(precision);
          // add the separators IF gm is group separator enabled
          newAmt = fx.addGroupSeparator(newAmt, precision);
        }
      }
      output.toggleClass('red', isNaN(amt));
      // If the backend errors on a base number that's too large, the number portion is
        // replaced with all #'s. Switch them with 1's so this number swap can recover
      output.text(output.text().replaceAll('#',1).replace(text_regex, newAmt + ' '));
    };
  
    /*
      Recalculate and rerender Scheduled Installment totals, both base currency and fx currency.
      FX currency version of function calPaymentSum() - in FX, no formatting
    */
    fx.calcScheduleTotal = function (arrayIndex) {
      var activeFX = fx.fxArray[arrayIndex];
      var fxField = jQuery('[data-fx-type=scheduled]').data('fxc-field');
      var pAmt = jQuery('[data-fxa-field='+fxField+']').find('input').val();
      var nPmts = jQuery('#x_number_of_payments').val();
  
      if (!activeFX.payment_code) {
        activeFX.payment_code = jQuery(activeFX.wrapper_selector).find('select, input').val();
        fx.lookupCurrencyRate(arrayIndex, activeFX.payment_code, 'payment', activeFX.request_id, activeFX.payment_id)
            .done(function() {
              fx.updateAllBaseRates(arrayIndex);
            });
      };
      if (!activeFX.payment_fxa_precision || !pAmt || !nPmts) return;
      var pSum = jQuery('#x_total_schedule_amount');
      var precision = activeFX.payment_fxa_precision;
      var total = 0;
      var numPay = 0;
  
      if (nPmts) {
        numPay = parseInt(nPmts.toString().replace(/[^\d]/g, ''), 10);
      } else {
        return;
      };
  
      if (pAmt && numPay >= 1 && pSum.length) {
        pAmt = pAmt.toString().replace(/[^0-9\.]/g, '');
        total = parseFloat(pAmt) * numPay;
        if (isNaN(total)) {
          total = 0;
          if (precision) total = total.toFixed(precision);
        } else if (activeFX.payment_rate && precision !== null) {
          total = +(Math.round(Number(total + "e+" + precision)) + "e-" + precision);
          total = total.toFixed(precision);
        } else {
          console.log('missing payment precision setting');
        };
        pSum.text(fx.addGroupSeparator(total, precision));
      } else {
        total = total.toFixed(precision);
        pSum.text(total);
      };
      pSum.text(pSum.text() + ' ' + activeFX.payment_code);
  
      var fxaElement = jQuery('[data-fxa-field=total_scheduled_payments] #x_total_schedule_amount');
      var baseElement = jQuery('[data-fxa-field=total_scheduled_payments] span.js-currency-base-fxa.scheduled')
      fx.updateBaseRate(fxaElement, baseElement, activeFX.payment_rate, activeFX.payment_precision);
    };
  
    /*
      Recalculate and rerender deductible amount when either x_amount_fxa 
      or x_quid_pro_quo_amount_fxa changes
    */
    fx.updateDeductibleAmountForRequest = function (requestId, multicurrencyMode) {
      var amount;
      var fairMarketVal;
      var precision = null;

      if (multicurrencyMode) {
        amount = jQuery('#x_amount_fxa' + requestId).val();
        fairMarketVal = jQuery('#x_quid_pro_quo_amount_fxa' + requestId).val();

        fx.fxArray.forEach(function(fxItem) {
          if (precision === null && fxItem.fx_type === 'payment' && fxItem.request_id === requestId) {
            precision = fxItem.payment_fxa_precision;
          }
        });
      } else {
        amount = jQuery('#x_amount').val();
        fairMarketVal = jQuery('#x_quid_pro_quo_amount').val();
        precision = fx.base_precision;
      }

      // Fallback precision if none found from payments/base
      if (precision === null) {
        if (fx.base_precision) {
          precision = fx.base_precision;
        } else {
          precision = 3;
        }
      }

      // Only update if both amount/fairMarketVal have a value, and their difference is greater than 0
      var deductibleAmount = 0;
      if (amount !== ""
        && fairMarketVal !== "") {
        // Turn input strings into usable numbers
        amount = fx.parseStringIntoFloat(amount, precision, multicurrencyMode);
        fairMarketVal = fx.parseStringIntoFloat(fairMarketVal, precision, multicurrencyMode);
        
        if (amount - fairMarketVal > 0) {
          deductibleAmount = amount - fairMarketVal;
        }
      }

      // Formatting
      deductibleAmount = +(Math.round(Number(deductibleAmount + "e+"+ precision)) + "e-" + precision);
      deductibleAmount = deductibleAmount.toFixed(precision);

      var deductibleAmountNoCommas = deductibleAmount;
      deductibleAmount = fx.addGroupSeparator(deductibleAmount, precision);

      // Update amount field
        // deductibleAmount: 'value' + &nbsp + fxc span
      var deductibleAmountField;

      if (multicurrencyMode) {
        deductibleAmountField = jQuery('#x_deductible_amount_fxa' + requestId);
      } else {
        deductibleAmountField = jQuery('#x_deductible_amount');
      }

      deductibleAmountField.val(deductibleAmountNoCommas);

      if (multicurrencyMode) {
        deductibleAmountField = deductibleAmountField.siblings('.p--background-styled.u-read-only--small');
        var DeductibleAmountHtmlAfterSpace = deductibleAmountField.html().split('&nbsp')[1];
        var newDeductibleAmountHtml = deductibleAmount + '&nbsp' + DeductibleAmountHtmlAfterSpace;
        jQuery(deductibleAmountField).html(newDeductibleAmountHtml);
      } else {
        var currencySymbol = '';
        if (fx.base_currency_symbol) {
          currencySymbol = fx.base_currency_symbol;
        }

        deductibleAmountField = deductibleAmountField.siblings('.p--background-styled');
        deductibleAmountField.text(currencySymbol + fx.addGroupSeparator(deductibleAmount, fx.base_precision));
      }

  
      if (multicurrencyMode) {
        // Build Base amount/formatting
        var newBaseAmt = (deductibleAmountNoCommas * fx.fxArray[0].request_rate);
        newBaseAmt = +(Math.round(Number(newBaseAmt + "e+"+fx.base_precision)) + "e-" + fx.base_precision);
        newBaseAmt = newBaseAmt.toFixed(fx.base_precision);
        // add the separators IF gm is group separator enabled
        newBaseAmt = fx.addGroupSeparator(newBaseAmt, fx.base_precision);
        var baseCurrencyString = '(' + newBaseAmt + ' ' + fx.base_code + ')';

        // Update Base field
        jQuery('#x_deductible_amount_fxa' + requestId)
          .siblings('.subtext.cg-tooltip-icon')
          .find('span.js-currency-base-fxa').text(baseCurrencyString);
      }
    };

    /*
      Make a string into a float by truncating the input without rounding
    */
    fx.parseStringIntoFloat = function (numAsString, precision, multicurrencyMode) {
      // Remove currency symbols in single currency mode
      if (!multicurrencyMode) {
        numAsString = numAsString.replace(fx.base_currency_symbol, '');
      }
      // Prefix number string with a 0 in case no integer portion included
      numAsString = '0' + numAsString;

      numAsString = numAsString.replace(new RegExp(fx.group_separator, "g"), '');
      // split input into substrings with decimal as the boundary between string 1 + 2
      var numberArray = numAsString.split('.');

      // If the array is length 1, no decimal place found
      if (numberArray.length === 1) {
        numberWithProperPrecision = parseInt(numberArray[0]);
      } else if (numberArray.length === 2) {
        var index = 0;
        var numberWithProperPrecision = parseInt(numberArray[0]);
        var decimalPortion = numberArray[1];

        // Loop until we have checked all the individual numbers in the decimal portion, 
        //  or until we have our needed precision
        for (index = 0; index < decimalPortion.length && index < precision; index ++) {
          numberWithProperPrecision *= 10;
          numberWithProperPrecision += parseInt(decimalPortion.charAt(index));
        }

        numberWithProperPrecision /= Math.pow(10,index);
      }
      // More than one decimal points found
      else {
        numberWithProperPrecision = 0;
      }

      return numberWithProperPrecision.toFixed(precision);
    };

    return {
      init: fx.init,
    };
  })();
  
  /* 
      Initialize the codes, rates, listeners for FXA and fxc
  */
  jQuery(function () {
    // basic selectors
      var fxcWrapperElements = jQuery('form [data-fxc-field]');
      var fxcSelector = 'select, input';
  
      CgFxCurrency.init(fxcWrapperElements, fxcSelector);
  });