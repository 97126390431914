/* GENERALIZABLE FOR ANY DRAG-DROP INTERFACE */
jQuery(function() {
  var $ = jQuery;
  $('body').on('dragstart', '[draggable=true]',
    function(event) {
      event.originalEvent.dataTransfer.setData(
          "Text",
          event.target.id
      );
    }).on('dragover dragenter', '.dropzone', function(event) {
      if (event.preventDefault) {event.preventDefault()};
    }).on('drop', '.dropzone', function(event) {
      var el = event.originalEvent.dataTransfer.getData("Text"); // what was dragged
      var dropTarget = $(event.target); // element it was dropped on
      var droppedAt = event.pageY; // coordinate it was dropped at
      var targetOffset = dropTarget.offset(); // coordinate of item dropped upon

      if (dropTarget.hasClass('dropzone')) {
        // Item was dropped directly on dropzone -- should mean no children in it
        dropTarget.append($('#' + el));
      } else {
        /* dropTarget was an element IN the dropzone
           Allow for dropping to a specific order.
        */
        if (!dropTarget.parent().hasClass('dropzone')) {
          /* Make sure not to append to lower level element by traversing up to
             the element whose parent is the dropzone
             e.g.: Given an li containing <span>s, <img>s, <p>s, set target as the <li>
          */
          dropTarget = dropTarget.closest('.dropzone > *');
          targetOffset = dropTarget.offset();
        }
        if (droppedAt > targetOffset.top + (.5 * dropTarget.height())) {
          dropTarget.after($('#' + el));
        } else {
          dropTarget.before($('#' + el));
        }
      }
      event.preventDefault();
    });
});

/* SPECIFIC FOR PORTAL STACKING */
jQuery(function() {
  jQuery('body').on('submit', '#portalForm', updateStacks);
});

function updateStacks() {
  jQuery('.dropzone:not(#unstacked)').find('.portlet').each(function() {
    var el = jQuery(this);
    var zone = el.closest('.dropzone').attr('id');
    var elid = el.attr('id').replace('portlet_','');
    var ptlt = '<input name="x_' + zone + '" type="hidden" value="' + elid + '">';
    jQuery('#portalForm').append(ptlt);
  });
}
